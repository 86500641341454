import React, { useState } from "react";
import AdminLayout from "../../Layout/AdminLayout";
import AddCompanyComp from "../../Components/modules/Manager/addManager";
import { useForm } from "react-hook-form";
import { useAddCompanyManagerMutation, useAddCompanyMutation } from "../../store/DashBoard/DashBoardApi";
import { toast } from "react-toastify";

export default function AddManagerAdmin() {
  const [modalShow, setModalShow] = useState(false);
  const [showImage, setShowImage] = useState();
  const {
    control,
    handleSubmit,
    setValue,
    setError,
    watch,
    getValues,
    clearErrors,
    reset,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      companyId: "",
      image: "",
      state: "",
      country: "",
      contactPhoneNo: "",
      address:""
    },
  });

  const [addCompanyManager, { isLoading, isSuccess: addCompanyIsSuccess }] =
  useAddCompanyManagerMutation();

  //useUploadFilesMutation

  const handleOnSubmit = async (data) => {
    console.log(data, "handleOnSubmit");

    const payload = {
      ...data,
      state: data?.state?.label,
      country: data?.country?.label,
      companyId:data?.companyId?.id,
      phoneNumber:`234${data?.contactPhoneNo}`
    };
    const response = await addCompanyManager(payload);

    if (response?.data) {
      toast.success('Manager successfully created')
      setModalShow(true);
      reset();
      setShowImage();
    }

    if(response?.error){
      toast.error(response?.error?.error?.data?.message)
      toast.error(response?.error?.data?.message)

    }

  console.log(response, "handleOnSubmit");
  };
  console.log(errors, "handleOnSubmit");

  return (
    <AdminLayout 
    onClick={handleSubmit(handleOnSubmit)}
    isLoading={isLoading}
    topText="Create Manager Profile" 
    header="others" active={3}>
      <AddCompanyComp
        setValue={setValue}
        watch={watch}
        control={control}
        getValues={getValues}
        errors={errors}
        addCompanyIsSuccess={addCompanyIsSuccess}
        modalShow={modalShow}
        setModalShow={setModalShow}
        setShowImage={setShowImage}
        showImage={showImage}
      />
    </AdminLayout>
  );
}
