import React from "react";
import ButtonComp from "../Ui/Button";
import { ImageComponent } from "./FallbackImage";
import { BsPlusLg } from "react-icons/bs";
import { useTranslation } from "react-i18next";

export default function TopHeader({
  navigate,
  title,
  report,
  btnText,
  addBtn,
  addBtnAction,
  plus,
  btnTextII
}) {
  const { t } = useTranslation();
  return (
    <div className="d-flex flex-wrap lg:flex-nowrap justify-content-between mb-[50px] items-center">
      <h4 className="font-semibold ms-2 mb-2 mb-lg-0 block lg:inline-block w-full lg:w-fit">
        {title || t(`lang.P`)}
      </h4>
      <div className="flex  justify-content-end  gap-4 w-full lg:w-fit">
        {!addBtn && (
          <ButtonComp
            // onClick ={addBtnAction}
            btnClassName={
              "border-[#A162F7] border-[1px]  rounded-[5px] sm:px-1 lg:px-[15px] py-[14px] "
            }
            text={
              <div className="flex gap-[12px] items-center text-[#A162F7] text-[14px]  lg:text-[16px] px-[10px]">
                {/* {!plus && <BsPlusLg size={20} />} */}
                <img src="/icon/download1.svg" />
                {btnText || "Download Report"}
              </div>
            }
          />
        )}

        {btnTextII && (
          <ButtonComp
            onClick={addBtnAction}
            btnClassName={
              "border-[#A162F7] border-[1px] bg-[#A162F7]  rounded-[5px] sm:px-1 lg:px-[40px] py-[14px] "
            }
            text={
              <div className="flex gap-[12px] items-center text-[#ffff] text-[14px]  lg:text-[16px] px-[10px]">
                {/* {!plus && <BsPlusLg size={20} />} */}
                {btnTextII||"Add Company"}
              </div>
            }
          />
        )}
      </div>
    </div>
  );
}
