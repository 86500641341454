/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { TableComp } from "../../Common/TableComp";
import { useNavigate, useParams } from "react-router-dom";
import OffCanvasComp from "../../Ui/OffCanvas";
import TopHeader from "../../Common/TopHeader";
import SubHeader from "../../Common/SubHeader";
import { CustomerColumns } from "./Data";
import ViewCustomerDetailsComp from "./submodules/ViewProductDetails";
import {
  useGetAllCustomerQuery,
  useGetAllLeadsQuery,
  useUpdateLeadsMutation,
} from "../../../store/Customer/CustomerApi";
import { FiEdit } from "react-icons/fi";
import ButtonComp from "../../Ui/Button";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useGetAllCompaniesQuery } from "../../../store/DashBoard/DashBoardApi";
export default function MedRepComp() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [showCavans, setShowCavans] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [LeadData, setLeadData] = useState();
  const [edit, setEdit] = useState(false);

  const {
    data: CustomerData,
    // isLoading: CustomerLoader,
    // isSuccess: CustomerSuccess,
  } = useGetAllCompaniesQuery();
  
 
  const tableDropDown = [
    {
      name: (
        <div className="text-[#656468] text-[13px] flex gap-2 items-center px-3">
          <FiEdit size={18} color="#4F008A" /> View Full Info
        </div>
      ),
      action: (row) => {
        console.log(row, "row");
        navigate(`/companies/${row?.id}`);
        // setShowCavans(true);
      },
    },
    {
      name: (
        <div className="text-[#656468] text-[13px] flex gap-2 items-center px-3">
          {/* <FiEdit size={18} color="#4F008A" />  */}
          <img src="/icon/refresh.svg" />
          Add New Manager
        </div>
      ),
      action: (row) => {
        console.log(row, "row");
        navigate(`/companies/${row?.id}`);
        // setShowCavans(true);
      },
    },

    {
      name: (
        <div className="text-[#D63434] text-[13px] flex gap-2 items-center px-3">
          <img alt="" src="/icon/red-trash.svg" />
          Delete Company
        </div>
      ),
      action: (row) => {
        console.log(row, "row");
        // setShowCavans(true);
      },
    },
  ];
  return (
    <div>
      <TopHeader
        // title={`Companies (${CustomerData?.data?.length || 0})`}
        title={`Medical Representatives `}
        btnText={"Report"}
        addBtnAction={() => navigate("/manager/add")}
        navigate={navigate}
        // btnTextII='Add Manager'
      />

      <div className="row  mb-5  flex-wrap ">
        {[
          { name: "Total Managers", count: 200 },
          { name: "Active Managers", count: 10 },
          { name: "TIn-Active Managers", count: 100 },
        ]?.map((item, i) => (
          <div className="w-full col-lg-4  flex-grow h-full" key={i}>
            <div className="px-lg-2 ">
              <div className="group hover:!text-white border-[#4F008A1A] border-[1px] px-4 rounded mb-3 mb-lg-0 pt-[30px] text-[#656468] pb-4 hover:bg-[#3DA84A]">
                <div className="d-flex justify-content-between align-items-start mb-3">
                  {/* <ImageComponent src="/icon/wallet.png" width={60} height={60} /> */}
                  <div className=" text-[18px]">{item?.name}</div>

                  <div>
                    <div className=" text-[18px]">
                      <img src="/icon/dots.svg" />
                    </div>
                  </div>
                </div>

                <div className=" text-[#060D1C] group-hover:text-white font-5  text-[25px]">
                  {item?.count}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* <SubHeader
      showFilterBtn
      statusAllBtn
        sortBtn
        inputPlaceholder={"Search Customer, Phone Number,Location"}
        onChange={(e)=>setSearchField(e.target.value)}      /> */}
      <div>
        <TableComp
          Data={[]
            ?.filter(
              (item) =>
                item?.name.toLowerCase().match(searchField?.toLowerCase()) ||
                item?.state.toLowerCase().match(searchField?.toLowerCase()) ||
                item?.contactPerson
                  .toLowerCase()
                  .match(searchField?.toLowerCase())
            )
            .reverse()}
          columnsComp={CustomerColumns(
            tableDropDown,
            CustomerData?.data?.length > 0 && []?.reverse()
          )}
          pagination
        />
      </div>
    </div>
  );
}
