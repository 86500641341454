import React, { useEffect, useMemo, useState } from "react";
import { ImageComponent } from "../../Common/FallbackImage";
import ButtonComp from "../../Ui/Button";
import { useNavigate } from "react-router-dom";
import InputComp from "../../Ui/InputComp";
import ModalComp from "../../Ui/ModalComp";
import { Country, State } from "country-state-city";

import SelectCompI from "../../Ui/SelectCompI";
import { Controller } from "react-hook-form";
import { REGEX_PATTERNS } from "../../../constants/errors";
import { useUploadFilesMutation } from "../../../store/DashBoard/DashBoardApi";
import { Spinner } from "react-bootstrap";
import {toast} from "react-toastify";

export default function AddCompanyComp({
  control,
  getValues,
  errors,
  watch,
  setValue,
  addCompanyIsSuccess,
  modalShow,
  setModalShow,
  showImage,
  setShowImage
}) {
  const navigate = useNavigate();

  const [image, setImage] = useState(null);
  const [uploadFile,{isLoading:fileLoading,}] =useUploadFilesMutation();

 
  const fileInputRef = React.useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange =async (event) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      const maxSize = 500 * 1024; // 500KB in bytes
      const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];

      if (allowedTypes.includes(file.type) && file.size <= maxSize) {
        const formData =new FormData();
        formData.append('file',file)
        const responses = await uploadFile(formData);
        setShowImage(responses?.data?.data?.file_link)
        setValue('image',responses?.data?.data?.file_link)
        // Here, you can perform any actions with the selected file
        console.log('Selected file:', responses?.data?.data);
      } else {
        toast.error('File type or size not allowed.')
        console.log('File type or size not allowed.');
      }
    
    }
  };

  useEffect(() => {
    if (getValues("country")?.countryCode) {
      setValue("state", null);
    }
  }, [watch("country")?.countryCode]);

  
  const getAllCountries = useMemo(() => {
    const originalArray = Country?.getAllCountries();
    if (!originalArray) return [];

    // Modify each item in the array and return the modified array
    return originalArray.map((item) => ({
      // ...item,
      label: item?.name,
      value: item?.name,
      countryCode: item?.isoCode,
    }));
  }, []);

  const filteredStates = useMemo(() => {
    const originalArray = State?.getAllStates();
    if (!originalArray) return [];

    // Modify each item in the array and return the modified array
    return originalArray
      .filter(
        (item) => item.countryCode === watch("country")?.countryCode || ""
      )
      .map((item) => ({
        label: item.name,
        value: item.name,
      }));
  }, [watch("country")?.countryCode]);

  const formList = [
    {
      name: "name",
      placeholder: "",
      label: "Company Name",
      type: "text",
    },
    {
      name: "contactPerson",
      placeholder: "",
      label: "Contact Person’s Name",
      type: "text",
    },
    {
      name: "email",
      placeholder: "",
      label: "Email address",
      type: "text",
      pattern:REGEX_PATTERNS?.EMAIL
    },
    {
      name: "contactPhoneNo",
      placeholder: "",
      label: "Phone Number",
      type: "text",
      pattern:REGEX_PATTERNS?.NUMBER

    },
    {
      name: "country",
      placeholder: "",
      label: "Country",
      type: "",
      option: getAllCountries,
    },
    {
      name: "state",
      placeholder: "",
      label: "State",
      type: "",
      option: filteredStates,
    },
    {
      name: "address",
      placeholder: "",
      label: "Full Address",
      type: "text",
    },
  ];

  // console.log(filteredStates, Country?.getAllCountries(), watch("country"));
  return (
    <div className="pb-[69px]">
      <div className="relative mb-[33px]">
        <img src="/Manager/bg.svg" className="w-full min-h-fit" />
        <img
          src="/Manager/emptyPicState.svg"
          className="absolute  bottom-[-80%] lg:bottom-[-50%] lg:left-[57px] w-[100px] lg:w-[152px]"
        />
      </div>
      <div className="flex justify-end mb-[61px]">
        <ButtonComp
          btnClassName={
            "border-[#A162F7] border-[1px]  rounded-[5px] sm:px-1 lg:px-[17px] py-[14px] "
          }
          text={
            <div className="flex gap-[12px] items-center text-[#A162F7] text-[14px]  lg:text-[18x] px-[30px]">
              {"View profile"}
            </div>
          }
        />
      </div>
      <div className="mb-[36px]">
        <div className="text-[22px] font-semibold">Company Profile</div>
        <div className="text-[#635F65] text-[14px]">
          Update your company details here
        </div>
      </div>

      <div className="mb-[60px]">
        {formList?.splice(undefined, 4)?.map((item, i) => (
          <div
            className="col-12 col-lg-11 row items-center pb-[20px] border-b-[1px] border-b-[#EDE5F3] !mt-[40px]"
            key={i}
          >
            <div className="col-3 text-[16px] text-[#b9b9b9]">
              {item?.label}
            </div>
            <div className="col-9 grow">
              <Controller
                name={item?.name}
                rules={{
                  required: `${item?.label} is required`,
                  pattern:item?.pattern
                }}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputComp
                    onChange={onChange}
                    value={value}
                    error={errors[item?.name]?.message}
                  
                  />
                )}
              />
            </div>
          </div>
        ))}

        <div className="col-11 flex items-center pb-[20px] border-b-[1px] border-b-[#EDE5F3] !mt-[40px]">
          <div className="col-2 text-[16px] text-[#b9b9b9] font-medium">
            Profile Image
          </div>
          <Controller
                name={'image'}
                rules={{
                  // required: `Image is required`,
                  // pattern:item?.pattern
                }}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
          <input 
          ref={fileInputRef}
          type="file"
          style={{ display: 'none' }}
          accept=".jpeg, .jpg, .png"
          onChange={(e)=>handleFileChange(e)}
          />
        )}
      />
          <img src={showImage?showImage:"/Manager/user.png"} className="!w-[60px] !h-[60px] object-cover rounded-full" />

          <div className="flex grow justify-center gap-[26px]">
            <ButtonComp text={<div className="text-[#635F65]" onClick={handleButtonClick}>
              {fileLoading?<Spinner size="sm"/>:'Upload'}
              
              </div>} />
            <ButtonComp text={<div className="text-[#635F65]">Delete</div>} />
          </div>
        </div>
      </div>

      <div className="mb-[36px]">
        <div className="text-[22px] font-semibold">Address and Territory</div>
        <div className="text-[#635F65] text-[14px]">
          Update your company address here
        </div>
      </div>
      <div>
        {formList?.splice(undefined, 2)?.map((item, i) => (
          <div className="col-12 col-lg-11 row items-center pb-[20px] border-b-[1px] border-b-[#EDE5F3] !mt-[40px]">
            <div className="col-3 text-[16px] text-[#b9b9b9]">
              {item?.label}
            </div>
            <div className="col-9 grow">
              <Controller
                name={item?.name}
                rules={{
                  required: `${item?.label} is required`,
                  pattern:item?.pattern

                }}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <SelectCompI
                    options={item?.option}
                    onChange={onChange}
                    value={value}
                    error={errors[item?.name]?.message}
                  />
                )}
              />
            </div>
          </div>
        ))}
        <div className="mb-[60px]">
          {formList?.splice(undefined, 1)?.map((item, i) => (
            <div
              className="col-12 col-lg-11 row items-center pb-[20px] border-b-[1px] border-b-[#EDE5F3] !mt-[40px]"
              key={i}
            >
              <div className="col-3 text-[16px] text-[#b9b9b9]">
                {item?.label}
              </div>
              <div className="col-9 grow">
                <Controller
                  name={item?.name}
                  rules={{
                    required: `${item?.label} is required`,
                    pattern:item?.pattern

                  }}
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <InputComp
                      onChange={onChange}
                      value={value}
                      error={errors[item?.name]?.message}
                    />
                  )}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <ModalComp
      show={modalShow}
      handleClose={()=>setModalShow(false)}
      size={'lg'}
      bodyComponents={<AddProductModal navigate={navigate} onClose={()=>{setModalShow(false)}}/>}
      />
    </div>
  );
}

const AddProductModal = ({ navigate,onClose }) => {
  return (
    <>
      <div className="flex justify-center mb-[40px]">
        <ImageComponent src="/icon/success-product.svg" />
      </div>
      <div className="text-center mb-[30px] lg:mb-[62px]">
        <h5 className="font-semibold text-[18px] lg:text-[22px] mb-[16px] mx-[30px] lg:mx-[72px]">
          Company Successfully Created
        </h5>
        <div className="text-[16px] text-[#555555]">
          The company has been successfully added and you can easily create
          company managers
        </div>
      </div>
      <div className="flex justify-center  mb-[64px] gap-[15px]">
        <ButtonComp
          onClick={() => navigate("/")}
          text={"Go to dashboard"}
          btnClassName={
            "text-[14px] lg:text-[16px]  py-[5px] lg:py-[13px]  px-[20px] lg:px-[44px] border-[0.64px] rounded-full border-[#3DA84A] text-[#3DA84A]"
          }
        />
        <ButtonComp
          onClick={onClose}
          btnClassName={
            "text-[14px] lg:text-[16px] py-[5px] lg:py-[13px] px-[25px] lg:px-[57px] border-[0.64px] rounded-full border-[#3DA84A] text-[#fff] bg-[#3DA84A]"
          }
          text={"Create Managers"}
        />
      </div>
    </>
  );
};
