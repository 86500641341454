import React from 'react'
import InputComp from '../../../Ui/InputComp'
import { Controller } from 'react-hook-form'

export default function ManagerPage({formList,control}) {
  return (
    <div>
    
        <div className="row  justify-between">
        <div className="col-5">
          <div className="row">
            {formList?.slice(undefined, 4)?.map((item, i) => (
              <div className={`${item?.style}`} key={i}>
                     <Controller
                name={item?.name}
                rules={{
                  required: `${item?.label} is required`,
                  pattern:item?.pattern
                }}
                control={control}
                render={({ field: { onChange, onBlur, value },formState:{errors} }) => (
                
                <InputComp
                  containerClassName={"mb-[20px]"}
                  label={item?.name}
                  labelClassName={
                    "!font-normal text-[#555555] text-[16px] mb-[15px]"
                  }
                  onChange={onChange}

                  placeholder={item?.placeholder}
                  value={value}
                  error={errors[item?.name]?.message}
                  
                  />
                )}
              />
              </div>
            ))}
          </div>
        </div>
        <div className="col-5">
          <div className="row">
            {formList?.slice(4, undefined)?.map((item, i) => (
              <div className={`${item?.style}`} key={i}>
                     <Controller
                name={item?.name}
                rules={{
                  required: `${item?.label} is required`,
                  pattern:item?.pattern
                }}
                control={control}
                render={({ field: { onChange, onBlur, value },formState:{errors} }) => (
                
                <InputComp
                wrapperClassName={"mb-[20px]"}
                  label={item?.name}
                  labelClassName={
                    "!font-normal text-[#555555] text-[16px] mb-[15px]"
                  }
                  onChange={onChange}

                  placeholder={item?.placeholder}
                  value={value}
                  error={errors[item?.name]?.message}
                  
                  />
                )}
              />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
