import React, { useEffect, useState } from "react";
import { ImageComponent } from "../../Common/FallbackImage";
import ButtonComp from "../../Ui/Button";
import { useNavigate, useParams } from "react-router-dom";
import InputComp from "../../Ui/InputComp";
import {
  useGetAllCompaniesQuery,
  useGetAllCompanyManagersQuery,
} from "../../../store/DashBoard/DashBoardApi";
import ManagerPage from "./submodules/ManagerPage";
import Header from "./submodules/Header";
import ShowProfileTop from "./submodules/showProfileTop";
import TopHeader from "../../Common/TopHeader";
import CompanyManagersComp from "./submodules/CompanyManagers";
import { BsCalendar2Event } from "react-icons/bs";
import SideDesc from "./submodules/SideDesc";
import MedRepsComp from "./submodules/MedReps";

export default function ViewCompanyComp({
  listPage,
  setListPage,
  control,
  handleOnSubmit,
  setValue,
  updateCompanyIsSuccess,
  Companies,
  fileInputRef,
  handleButtonClick,
  showImage,
  fileLoading,
  handleFileChange,
  allMedReps
}) {
  const [company, setCompany] = useState();
  const navigate = useNavigate();

  const pages = ["Overview", "Managers", "Med Reps"];
  const { id } = useParams();
  const queryOptions = {
    query: { id }, // Pass the parameters along with the query
    enabled: false, // Enable the query only if shouldFetchData is true
    skip: true,
  };

  
  const { data: CompanyManagers } = useGetAllCompanyManagersQuery(id, {
    skip: !id,
    refetchOnMountOrArgChange: true,
    
  });

  useEffect(() => {
    setValue('name',company?.name);
    setValue('email',company?.email);
    setValue('address',company?.address);
    setValue('contactPhoneNo',company?.contactPhoneNo);
    setValue('contactPerson',company?.contactPerson);
    setValue('state',company?.state);
    setValue('country',company?.country);
  
  }, [company?._id])
  

  useEffect(() => {
    if (id) {
      setCompany(Companies?.data?.companies?.find((item) => item?._id === id));
    }
  }, [id, Companies?.data?.companies]);

  const formList = [
    {
      name: "name",
      label: "Company Name",
      placeholder: "General Hospital",
      value: '',
    },
    {
      name: "contactPerson",
      label: "Contact Person’s Name",
      placeholder: "May and Baker Pharm. Ltd",
      value: company?.contactPerson,
    },
    {
      name: "country",
      placeholder: "",
      label: "Country",
      style: "col-lg-6",
      value: company?.country,
    },
    {
      name: "state",
      placeholder: "",
      label: "State",
      style: "col-lg-6",
      value: company?.state,
    },
    {
      name: "email",
      placeholder: "",
      label: "Email address",
      value: company?.email,
    },
    {
      name: "contactPhoneNo",
      placeholder: "",
      label: "Phone Number",
      iconRight: "+234",
      value: company?.contactPhoneNo,
    },
    {
      name: "address",
      label: "Company Full Address",
      placeholder: "216, Broad street, Lagos",
      value: company?.address,
    },
  ];

  const pageSet = [
    {
      name: "Overview",
      component: <ManagerPage control={control} formList={formList} />,
    },
    {
      name: "Managers",
      component: <CompanyManagersComp Data={CompanyManagers?.data?.managers} />,
    },
    {
      name:'Med Reps',
      component: <MedRepsComp Data={allMedReps} />
    }
  ];

  return (
    <div className={`flex gap-2 h-min-[90vh]`}>
      <div
        className={`pb-[69px] w-full ${
          listPage !== "Overview" && " xl:w-[75%]"
        }`}
      >
        <TopHeader
          // title={`Companies (${CustomerData?.data?.length || 0})`}
          title={`Companies `}
          btnTextII={"Add Manager"}
          btnText={"Download Report"}
          addBtnAction={() => navigate("/manager/add")}
          navigate={navigate}
        />
        {listPage === "Overview" && (
          <ShowProfileTop company={company} handleOnSubmit={handleOnSubmit} 
          updateCompanyIsSuccess={updateCompanyIsSuccess}
          fileInputRef={fileInputRef}
          handleButtonClick={handleButtonClick}
          uploadImage={showImage}
          fileLoading={fileLoading}
          handleFileChange={handleFileChange}
          />
        )}

        <Header
          listPage={listPage}
          setListPage={setListPage}
          pages={pages}
          showFull={listPage === "Overview" ? true : false}
        />

        {pageSet?.find((item) => item?.name === listPage)?.component}
      </div>
      <div className={listPage !== "Overview" && `xl:w-[25%]`}>
        {listPage !== "Overview" && <SideDesc data={company} />}
      </div>
    </div>
  );
}

const AddProductModal = ({ navigate }) => {
  return (
    <>
      <div className="flex justify-center mb-[40px]">
        <ImageComponent src="/icon/success-product.svg" />
      </div>
      <div className="text-center mb-[30px] lg:mb-[62px]">
        <h5 className="font-semibold text-[18px] lg:text-[22px] mb-[16px] mx-[30px] lg:mx-[72px]">
          Company Successfully Created
        </h5>
        <div className="text-[16px]">
          The company has been successfully added and you can easily create
          company managers
        </div>
      </div>
      <div className="flex justify-center  mb-[64px] gap-[15px]">
        <ButtonComp
          onClick={() => navigate("/")}
          text={"Go to dashboard"}
          btnClassName={
            "text-[14px] lg:text-[16px]  py-[5px] lg:py-[13px]  px-[20px] lg:px-[44px] border-[0.64px] rounded-full border-[#3DA84A] text-[#3DA84A]"
          }
        />
        <ButtonComp
          onClick={() => navigate("/companies/add")}
          btnClassName={
            "text-[14px] lg:text-[16px] py-[5px] lg:py-[13px] px-[25px] lg:px-[57px] border-[0.64px] rounded-full border-[#3DA84A] text-[#fff] bg-[#3DA84A]"
          }
          text={"Create Managers"}
        />
      </div>
    </>
  );
};
