import React from 'react'
import { TableComp } from '../../../Common/TableComp'
import { CustomerColumns } from './Data'
import { FiEdit } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

export default function CompanyManagersComp({Data}) {
  const navigate = useNavigate();
  // const {} =useHis
  console.log(Data,'Data')
  const tableDropDown = [
    {
      name: (
        <div className="text-[#656468] text-[13px] flex gap-2 items-center px-3">
          <FiEdit size={18} color="#4F008A" /> View Full Info
        </div>
      ),
      action: (row) => {
        console.log(row, "row");
        navigate(`/manager/${row?._id}`,{state:{...row}});
        // setShowCavans(true);
      },
    },
    {
      name: (
        <div className="text-[#656468] text-[13px] flex gap-2 items-center px-3">
          {/* <FiEdit size={18} color="#4F008A" />  */}
          <img src="/icon/refresh.svg" />
          Add New Manager
        </div>
      ),
      action: (row) => {
        console.log(row, "row");
        navigate(`/companies/${row?.id}`);
        // setShowCavans(true);
      },
    },

    {
      name: (
        <div className="text-[#D63434] text-[13px] flex gap-2 items-center px-3">
          <img alt="" src="/icon/red-trash.svg" />
          Delete Company
        </div>
      ),
      action: (row) => {
        console.log(row, "row");
        // setShowCavans(true);
      },
    },
  ];
  return (
    <div className='w-[100%]'>
         <div className="row  g-3  flex-wrap  mb-[54px]">
          {[
            { name: "Total Managers", count: Data?.length },
            { name: "Active Managers", count: 0 },
            { name: "In-Active Managers", count: 0 },
          ]?.map((item, i) => (
            <div className="w-full col-lg-6 col-xl-4  flex-grow h-full" key={i}>
              <div className="px-lg-2 ">
                <div className="group hover:!text-white border-[#4F008A1A] border-[1px] px-4 rounded mb-3 mb-lg-0 pt-[30px] text-[#656468] pb-4 hover:bg-[#3DA84A]">
                  <div className="d-flex justify-content-between align-items-start mb-3">
                    {/* <ImageComponent src="/icon/wallet.png" width={60} height={60} /> */}
                    <div className=" text-[18px]">{item?.name}</div>

                    <div>
                      <div className=" text-[18px]">
                        <img src="/icon/dots.svg" />
                      </div>
                    </div>
                  </div>

                  <div className=" text-[#060D1C] group-hover:text-white font-5  text-[25px]">
                    {item?.count}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className='overflow-hidden'>
        <TableComp
          Data={Data
           
          }
          columnsComp={CustomerColumns(
            tableDropDown,
            Array.isArray(Data)&& [...Data]

          )}
          pagination
        />
        </div>
    </div>
  )
}
