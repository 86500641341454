import React from 'react'
import { ImageComponent } from './FallbackImage'
import Skeleton from 'react-loading-skeleton';
import {RiMenu2Line} from 'react-icons/ri'
import { useTranslation } from "react-i18next";
import ButtonComp from '../Ui/Button';
import { useNavigate } from 'react-router-dom';

export default function HeaderDesc({data,setToogle,topText,onClick,isLoading}) {
  const navigate =useNavigate();

  console.log(data,'userProfile')

  return (
    <div className='flex  flex-wrap justify-between items-center py-[30px]  px-3 border-b-[#EDE6F5] border-b-[1px]'>
      <div className='text-[28px] font-bold'>{topText||`Create Company Profile`}</div>
      <div>
      <div className="flex  justify-content-end  gap-4 w-full lg:w-fit">
        
        <ButtonComp
        onClick ={()=>navigate(-1)}
            btnClassName={
              "border-[#A162F7] border-[1px]  rounded-[5px] sm:px-1 lg:px-[17px] py-[14px] "
            }
            text={
              <div className="flex gap-[12px] items-center text-[#A162F7] text-[14px]  lg:text-[16x] px-[30px]">
                {/* {!plus && <BsPlusLg size={20} />} */}
                {/* <img src='/icon/download1.svg'/> */}
                 {'Cancel'}
              </div>
            }
          />
           <ButtonComp
          onClick ={onClick}
          loader={isLoading}
            btnClassName={
              "border-[#A162F7] border-[1px] bg-[#A162F7]  rounded-[5px] sm:px-1 lg:px-[40px] py-[14px] "
            }
            text={
              <div className="flex gap-[12px] items-center text-[#ffff] text-[14px]  lg:text-[16px] px-[10px]">
                {/* {!plus && <BsPlusLg size={20} />} */}
                 {'Save Changes'}
              </div>
            }
          />
          
        </div>
      </div>

    </div>
  )
}
