import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../api";
import moment from "moment";

export const DashBoardApi = createApi({
  reducerPath: "DashBoardApi",
  baseQuery: baseQuery,
  tagTypes: ['getAllCompanies','getAllCompanyManagers'],

  endpoints: (builder) => ({
    dashboard_metric: builder.query({
      query: ({StartDateEnhanced, EndDateEnhanced}) => ({
        url: `/metrics/orders-range?startDate=${moment(StartDateEnhanced).valueOf() / 1000
        }&endDate=${moment(EndDateEnhanced).valueOf() / 1000}`,
        method: "GET",
      }),
    }),
    graphData: builder.query({
      query: ({monthId}) => ({
        url: `/metrics/charts?months=${monthId}`,
        method: "GET",
      }),
    }),
    getAllManager: builder.query({
      query: (month) => ({
        url: `/metrics/top-areas`,
        method: "GET",
      }),
    }),
    AllSignups: builder.query({
      query: (month) => ({
        url: `/auth/signups`,
        method: "GET",
      }),
    }),
    mainDashBoardMap: builder.query({
      query: (companyId) => ({
        url: `company/staffs-locations/${companyId}`,
        method: "GET",
      }),
    }),
    uploadFiles: builder.mutation({
      query: (file) => ({
        url: `/file/upload`,
        method: "POST",
        body:file
      }),
    }),
    getAllCompanies: builder.query({
      query: (companyId) => ({
        url: `/admin/companies`,
        method: "GET",
      }),
      providesTags:(_)=> ['getAllCompanies'], // Specify the same tag(s) used in the mutation


    }),
    getAllCompanyManagers: builder.query({
      query: (id) => ({
        url: `/admin/company/${id}/managers`,
        method: "GET",
      }),
      providesTags:(_)=> ['getAllCompanyManagers'], // Specify the same tag(s) used in the mutation

    }),
    getAllCompanyMedReps: builder.query({
      query: (companyId) => ({
        url: `/admin/company/${companyId}/med-reps`,
        method: "GET",
      }),
      providesTags:(_)=> ['getAllCompanyManagers'], // Specify the same tag(s) used in the mutation

    }),
    addCompany: builder.mutation({
      query: (payload) => ({
        url: `/admin/company/create`,
        method: "POST",
        body: payload,
      }),
      transformResponse: (response) => response,
      providesTags:(_)=> ['getAllCompanyManagers'],
    }),
    addCompanyManager: builder.mutation({
      query: (payload) => ({
        url: `/admin/company/manager/create`,
        method: "POST",
        body: payload,
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['getAllCompanyManagers'], // Specify queries to invalidate


    }),
    updateCompany: builder.mutation({
      query: (payload) => ({
        url: `/admin/company/${payload?.id}/update`,
        method: "PATCH",
        body: payload,
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['getAllCompanies'], // Specify queries to invalidate


    }),
    updateManager: builder.mutation({
      query: (payload) => ({
        url: `/admin/company/manager/${payload?.id}/update`,
        method: "PATCH",
        body: payload,
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['getAllCompanies'], // Specify queries to invalidate


    }),
  }),
});

export const {
  useDashboard_metricQuery,
  useGraphDataQuery,
  useMainDashBoardMapQuery,
  // 
  useGetAllCompaniesQuery,
  useGetAllCompanyManagersQuery,
  useAddCompanyMutation,
  useAddCompanyAdminMutation,
  useAddCompanyManagerMutation,
  useUpdateCompanyMutation,
    useAllSignupsQuery,
    useUploadFilesMutation,
    useUpdateManagerMutation,
    useGetAllCompanyMedRepsQuery
  
} = DashBoardApi;
