import React, { useState } from "react";
import AdminLayout from "../../Layout/AdminLayout";
import ViewCompanyComp from "../../Components/modules/Company/viewCompany";
import ViewManagerComp from "../../Components/modules/Manager/ViewManager";
import { useForm } from "react-hook-form";
import { useUpdateManagerMutation, useUploadFilesMutation } from "../../store/DashBoard/DashBoardApi";
import { toast } from "react-toastify";

export default function ViewCompany() {
  const [showImage,setShowImage]=useState()
  const {
    control,
    handleSubmit,
    setValue,
    setError,
    watch,
    getValues,
    clearErrors,
    reset,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      address: "",
      contactPhoneNo: "",
      contactPerson: "",
      state: "",
      country: "",
      image: "",
    },
  });
  const [uploadFile,{isLoading:fileLoading,}] =useUploadFilesMutation();

  const fileInputRef = React.useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange =async (event) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      const maxSize = 500 * 1024; // 500KB in bytes
      const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];

      if (allowedTypes.includes(file.type) && file.size <= maxSize) {
        const formData =new FormData();
        formData.append('file',file)
        const responses = await uploadFile(formData);
        setShowImage(responses?.data?.data?.file_link)
        setValue('image',responses?.data?.data?.file_link)
        // Here, you can perform any actions with the selected file
        console.log('Selected file:', responses?.data?.data);
      } else {
        toast.error('File type or size not allowed.')
        console.log('File type or size not allowed.');
      }
    
    }
  };
  const [updateManager, { isLoading, isSuccess }] = useUpdateManagerMutation();

  const handleOnSubmit = async (data) => {
    console.log(data, "handleOnSubmit");

    const payload = {
      id:data?.id,
      "firstName": data?.firstName,
      "lastName": data?.lastName,
      "email": data?.email,
      "address": data?.address,
      "contactPhoneNo": data?.contactPhoneNo,
    
      "state": data?.state?.join(', '),
      "country": data?.country,
      image:data?.image
    };

    console.log(data, "Data");
    const response = await updateManager(payload);

    if (response?.data) {
      toast.success("Manager Updated successfully...");
      // refresh();

      // setModalShow(true);
      // reset();
      // setShowImage();
    }

    if (response?.error) {
      toast.error(response?.error?.error?.data?.message);
      toast.error(response?.error?.data?.message);
    }

    // console.log(response, "handleOnSubmit");
  };
  return (
    <AdminLayout active={4}>
      <ViewManagerComp handleOnSubmit={handleOnSubmit}
       handleButtonClick={handleButtonClick}
       fileInputRef={fileInputRef}
       handleFileChange={handleFileChange}
       fileLoading={fileLoading}
       showImage={showImage}
       control={control}
       handleSubmit={handleSubmit}
       setValue={setValue}
       errors={errors}
      />
    </AdminLayout>
  );
}
