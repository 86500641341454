import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useGetUserProfileQuery } from '../store/User/userApi';
import { isAuth, logout, selectCurrentUserData } from '../store/auth';
import { storage } from '../utils/helper';
import moment from 'moment';

const withAuth = (WrappedComponent, redirectPath = '/login') => {
  const AuthComponent = (props) => {
    const dispatch = useDispatch();
    // const { data: userProfile, isSuccess, isError } = useGetUserProfileQuery();
    // const user = userProfile?.data;
    // const isAuthState = useSelector(isAuth);
    // const getUser = useSelector(selectCurrentUserData);
    // console.log(userProfile?.data?.userRole==="company_admin",'isAuthState')
    // console.log(getUser,'datelin')
    // useEffect(() => {
    //   if (isError&&storage['localStorage'].get('user_details_profile')?.token) {
    //     dispatch(logout());
    //   }
    // }, [isError,isAuthState]);

    // if (!storage['localStorage'].get('user_details_profile')?.token) {
    //   return <Navigate to={redirectPath} />;
    // }
    // if (getUser?.userRole!=="admin") {
    //   return <Navigate to={redirectPath} />;
    // }

    // if (isSuccess && user) {
    //   return <WrappedComponent user={user} {...props} />;
    // }

    return null;
  };

  return AuthComponent;
};

export default withAuth;
