import React from 'react'

export default function Header({pages,setListPage,listPage,showFull}) {
  return (
    <div>
        <div className="flex justify-between items-end border-b-[1px] border-b-[#EDE5F3] mb-[40px]">
        <div className="flex gap-3  ">
          {pages?.map((item, i) => (
            <div
              key={i}
              onClick={() => setListPage(item)}
              className={`text-[18px] cursor-pointer  from-neutral-600  pb-[20px] ${
                item === listPage &&
                "border-b-[#A162F7] text-[#A162F7] border-b-[2px]"
              }`}
            >
              {item}
            </div>
          ))}
        </div>
       {showFull && <div className="  ">
          <div className="text-[22px] font-semibold text-right ">
            Company Profile
          </div>
          <div className="text-[#635F65] text-[14px] text-right pb-[20px]">
            View your company details here
          </div>
        </div>}
      </div>
    </div>
  )
}
