import React, { useState } from "react";
import AdminLayout from "../../Layout/AdminLayout";
import AddCompanyComp from "../../Components/modules/Company/addproduct";
import { useForm } from "react-hook-form";
import { useAddCompanyMutation, useUploadFilesMutation } from "../../store/DashBoard/DashBoardApi";

export default function AddCompany() {
  const [modalShow,setModalShow]=useState(false)
  const [showImage,setShowImage]=useState()
  const {
    control,
    handleSubmit,
    setValue,
    setError,
    watch,
    getValues,
    clearErrors,
    reset,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      "name": "Test company",
      "email": "company@gmail.com",
      "address": "address",
      "contactPhoneNo": "09085581235",
      "contactPerson": "Name",
      "state": "",
      "country": "",
      image:''
    },
  });

  const [
    addCompany,
    { isLoading, isSuccess: addCompanyIsSuccess },
  ] = useAddCompanyMutation();

  //useUploadFilesMutation


  const handleOnSubmit =async (data) => {
    console.log(data, "handleOnSubmit");

    const payload ={
      ...data,
      state:data?.state?.label,
      country:data?.country?.label
    }
    const response =await addCompany(payload);

    if(response?.data){
      setModalShow(true);
      reset();
      setShowImage()
    }

    console.log(response, "handleOnSubmit");
  };
  console.log(errors, "handleOnSubmit");

  return (
    <AdminLayout onClick={handleSubmit(handleOnSubmit)}
    isLoading={isLoading}
    header={2} active={4}>
      <AddCompanyComp
        setValue={setValue}
        watch={watch}
        control={control}
        getValues={getValues}
        errors={errors}
        addCompanyIsSuccess={addCompanyIsSuccess}
        modalShow={modalShow}
        setModalShow={setModalShow}
        setShowImage={setShowImage}
        showImage={showImage}
      />
    </AdminLayout>
  );
}
