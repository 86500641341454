import './App.css';
import {
  Routes,
  Route,
} from 'react-router-dom';
import { ProtectedRoute } from './Hoc';
import DashBoard from './Pages/DashBoard';
import Login from './Pages/Login';
import SignUp from './Pages/SignUp';

import { store } from '../src/store';
import { Provider } from 'react-redux';

import ForgetPassword from './Pages/ForgetPassword';
import AddCompany from './Pages/Customers/AddProduct';

import Companies from './Pages/Customers/Customers';
import Manager from './Pages/Manager/Manager';
import AddManagerAdmin from './Pages/Manager/AddManager';
import MedRep from './Pages/MedRep/MedRep';
import ViewCompany from './Pages/Manager/ViewManager';
import ViewCompanyMain from './Pages/Customers/ViewCompany';

function App() {
  //Manager
  return (
    <Provider store={store}>
      
    <Routes>
   
    <Route path="login" element={<Login />} /> 
    <Route path="Signup" element={<SignUp />} /> 
    <Route path="forget_password" element={<ForgetPassword />} /> 
    {/* <Route path="/" element={<DashBoard />} /> */}

    <Route element={
    <ProtectedRoute  user={{}} />}
    >
      <Route path="/" element={<DashBoard />} />
      <Route path="home" element={<Home  />} />
   
      <Route path="companies" element={<Companies  />} />
      <Route path="companies/:id" element={<ViewCompanyMain  />} />
      <Route path="companies/add" element={<AddCompany  />} />
      <Route path="manager/:id" element={<ViewCompany  />} />
      <Route path="manager/add" element={<AddManagerAdmin  />} />
      <Route path="med_rep" element={<MedRep  />} />
      
   
    </Route>
 
    <Route path="*" element={<p>There's nothing here: 404!</p>} />
  </Routes>
  </Provider>
  );
}

const Home = () => {
  return <h2>Home (Protected: authenticated user required)</h2>;
};

export default App;
