import React from 'react'
import AdminLayout from '../../Layout/AdminLayout'
import CustomerComp from '../../Components/modules/Customer'

export default function Companies() {
  return (
    <AdminLayout header='main' active={4}>
      <CustomerComp/>
    </AdminLayout>
  )
}
