import React from 'react'
import ButtonComp from '../../../Ui/Button'

export default function ShowProfileTop({company,handleOnSubmit,
  handleFileChange,
  fileInputRef,
  fileLoading,
  uploadImage,
  handleButtonClick
}) {
  return (
    <div>
        <div className="relative mb-[33px]">
        <img src="/Manager/bg.svg" className="w-full" />
        <div className="absolute  bottom-[-80%] lg:bottom-[-50%] lg:left-[57px] w-[100px] lg:w-[152px] flex items-center gap-[12px]">
        <input 
          ref={fileInputRef}
          type="file"
          style={{ display: 'none' }}
          accept=".jpeg, .jpg, .png"
          onChange={(e)=>handleFileChange(e)}
          />
          <img src={uploadImage||company?.image||"/Manager/emptyPicState.svg"} className=" cursor-pointer w-[60px] h-[60px] lg:w-[112px] lg:h-[112px] rounded-full object-contain bg-white"  onClick={handleButtonClick} />
          <div className="whitespace-nowrap text-[24px] font-bold">
            {company?.name}
          </div>
        </div>
      </div>
      <div className="flex justify-end mb-[61px]">
        <ButtonComp
        onClick={()=>handleOnSubmit()}
          btnClassName={
            "border-[#A162F7] border-[1px]  rounded-[5px] sm:px-1 lg:px-[17px] py-[14px] "
          }
          text={
            <div className="flex gap-[12px] items-center text-[#A162F7] text-[14px]  lg:text-[18x] px-[30px]">
              {"Edit profile"}
            </div>
          }
        />
      </div>
    </div>
  )
}
