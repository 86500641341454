import React from "react";
import { SideBarData } from "../Data/SideBar";
import { Link, useNavigate } from "react-router-dom";
import { ImageComponent } from "./FallbackImage";
import { useDispatch } from "react-redux";
import { logout } from "../../store/auth";
import { useGetUserProfileQuery } from "../../store/User/userApi";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import ButtonComp from "../Ui/Button";
import {HiDotsVertical} from 'react-icons/hi'
import DropDownComp from "../Ui/DropDown";
export default function SideBar({ active }) {
  const navigate = useNavigate();
  
  // const { data: userProfile } = useGetUserProfileQuery();
  const [dropDown, setDropDown] = useState(false);
  

  const dispatch = useDispatch();
  return (
    <div className="overflow-y-scroll h-[100vh] check overflow-x-hidden">
      <div
        className="position-relative mb-5 text-center flex justify-center mt-[10px]"
        style={{ height: "100px" }}
      >
        <ImageComponent
         
          className="object-contain w-[90%]"
          src={"/logo1.svg"}
        />
      </div>
      <div className="px-3 ">
        <div className="">
          {SideBarData()?.map(
            ({ name, icon, icon1, path, activeI, subItem }, i) => (
              <div
                key={i}
                // to={path}
              >
                <div
                  className={`ps-4 d-flex align-items-center gap-3 fw-medium mb-2 py-3  px-3 cursor-pointer ${
                    active === activeI
                      ? "bg-[#f5eff8] text-[#333333] "
                      : "blackII"
                  }`}
                  onClick={() => navigate(path)}
                >
                  {icon1 }
                  {name}
                </div>
              </div>
            )
          )}
        </div>
        <div className=" pt-[155px]" style={{ height: "20vh" }}>
          <div className="border-b-[#E5E5EF] border-b-[1px] border-t-[1px] border-t-[#E5E5EF] h-[132px] mb-[64px]"></div>
          <div className="flex  items-center gap-4 mb-[32px]">
          <div className="flex  items-center gap-2">
            <img src="/icon/user.png" className="w-[40px]"/>
            <div>
            <div className="text-[14px] text-[#6A6A6A]">Administrator</div>
              <div className="font-medium">Mr. May & Bakers</div>
            </div>
        
            
            </div>
            <div>     
              <DropDownComp
              dropDownText={<HiDotsVertical/>}
              options={[{name: 'Log Out',action:()=>{
                navigate('/login')
              }}]}
              />
            </div>
          </div>

          <div className="border-[1px] border-[#E5E5EF] px-[9px] py-[7px] flex justify-between  gap-3">
            <ButtonComp
            text={'English'}
            btnClassName={'w-full bg-[#EDE6F3] text-black col-12 text-[14px] py-[10px]  font-medium'}
            wrapper={'flex-1 '}
            />
            <ButtonComp
            text={'French'}
            btnClassName={'w-full bg-[#ffff] py-[10px] text-[#757575] col-12 text-[14px] font-medium'}
            wrapper={'flex-1 '}
            />
          </div>
         
              <div className=" pt-[155px]" style={{ height: "20vh" }}></div>
        </div>
      </div>
    </div>
  );
}
