import React from "react";
import Select from 'react-select';
export default function SelectCompI({
  
  containerClassName,
  label,
  labelClassName,
  wrapperClassName,
  placeholder,
  value,
  options=[],
  onChange,
  error
}) {

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: 50,
      placeholder: {
        color: '#757575',
      
      },
      border: `1px solid ${state.isFocused ? '#EDE6F3' : '#ccc'}`,
      boxShadow: state.isFocused ? null : null,
      '&:hover': {
        border: `1px solid ${state.isFocused ? '#EDE6F3' : '#ccc'}`
      }
    })
  };

  return (
   <div className={wrapperClassName}>
    {label && <div className={`mb-[10px] font-medium ${labelClassName}`}>{label}</div>}
    <Select
    styles={customStyles}
        value={value}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        // autoFocus={true}
        className="placeholder:text-start"
        theme={theme => ({
          ...theme,
          colors: {
              ...theme.colors,
              neutral50: '#757575',  // Placeholder color
          },
      })}
      />
      {error && (
        <div className="text-danger text-start text-[14px] mt-[4px] ps-1">
          {label} {error}
        </div>
      )}
   </div>
  );
}


