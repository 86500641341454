import React, { useEffect } from "react";
import DatePickerInput from "../Ui/CalendarComp";
import { ImageComponent } from "../Common/FallbackImage";
import { BsThreeDotsVertical } from "react-icons/bs";
import { GoPrimitiveDot } from "react-icons/go";
import ButtonComp from "../Ui/Button";
import { TableComp } from "../Common/TableComp";
import ContainerComp from "../Common/Container";
import { SimpleChart } from "../Common/Charts/Chart1";
import { BiDotsVerticalRounded } from "react-icons/bi";
import {AiOutlineDown} from 'react-icons/ai'
import {
  useDashboard_metricQuery,
  useGraphDataQuery,
  useMainDashBoardMapQuery,
  useTopAreasDataQuery,
} from "../../store/DashBoard/DashBoardApi";
import { formatMoney } from "../../utils/formatMoney";
import { useGetAllProductQuery } from "../../store/Product/ProductApi";
import { DashBoardProductColumns, getMonth, mSList } from "./Data";
import { Months, YearMonth, firstDateOfTheMonth } from "../../utils/helper";
import { useGetAllOrderQuery } from "../../store/Order/OrderApi";
import DateFilter from "../Common/DateFilter";
import { useNavigate } from "react-router-dom";
import ShowMapCompI from "./Schedule/showMap copy";
import { useTranslation } from "react-i18next";
import DropDownCompII from "../Ui/DropDownII";
import { useState } from "react";
import moment from "moment";
import Beta from "../Common/beta";
import DropDownComp from "../Ui/DropDown";
import AreaChartComp from "../Common/Charts/AreaChart";
export default function DashBoardComp() {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(firstDateOfTheMonth());
  const [endDate, setEndDate] = useState(new Date());
  const d = new Date();
// let name = month[d.getMonth()];
  const [yearForGraph,setYearForGraph]=useState({name:mSList[d.getMonth()],value:d.getMonth()});
  const [months,setMonths]=useState(YearMonth(t))

  console.log(yearForGraph,'yearForGraph')

  // const { t } = useTranslation();
  const { t, i18n, ready } = useTranslation();

  useEffect(() => {
    const lng = navigator.language;
    i18n.changeLanguage(lng);
  }, [i18n]);

  // useEffect(() => {
  //   setMonths(YearMonth())
  // }, [])
  

  const StartDateEnhanced = moment(startDate).format("YYYY-MM-DD");
  const EndDateEnhanced = moment(endDate).add(1, 'days').format("YYYY-MM-DD");

  // console.log(moment(startDate).format("YYYY-MM-DD"),endDate,'firstDateOfTheMonth')

 
  // console.log(mainDashBoardMap?.data,'mainDashBoardMap')
  // console.log(YearMonth(t)[1],'YearMonth')
  const getGraphData = () => {
    const newArray = [];
    // yearForGraph===2023 ? 
    //  GraphData?.data?.map((item) => item?.month <= new Date().getMonth()&&
    //   newArray.push({
    //     month: months[item?.month - 1],
    //     totalSalesOrders: item?.orders?.totalAmount,
    //     totalSalesCollection: item?.orders?.totalSalesCollection,
    //   })
    //   // :
    // ):
    GraphData?.data?.map((item) => 
      newArray.push({
        month: mSList[item?.month - 1],
        totalSalesOrders: item?.orders?.totalAmount,
        totalSalesCollection: item?.orders?.totalSalesCollection,
      })
    )
    // console.log(newArray,'newArray')
    return newArray;
  };

  if (!ready) {
    return <div></div>;
  }
  // console.log(getGraphData(),new Date().getMonth(),'getGraphData')
  return (
    <div>
      <div className="d-flex justify-content-between mb-4">
        <h4 className="font-2 ms-2">{t("lang.Overview")} </h4>
        {/* <h4 className="font-2 ms-2">{t('lang.hello')} </h4>
        <span>
          Browser Language:{lng}
        </span> */}
        {/* <DateFilter t={t} 
         filterLoader={dashBoardMetricLoader||dashBoardMetricFetching}
         filterStartDate={startDate}
         filterEndDate={endDate}
         filterStartSetDate={setStartDate}
         filterEndSetDate={setEndDate}
         dateFormat="MM/dd/yyyy"
        /> */}
      </div>
     
      <div className="row  mb-5  flex-wrap ">
        {[{name:'Total Company',count:10}, {name:'Total Managers',count:10}, {name:'Total Field Reps',count:100},{name: 'Total Sign Ups',count:29}]?.map((item,i)=>
         <div className="w-full col-lg-3  flex-grow h-full" key={i}>
         <div className="px-lg-2 ">
         <div className="group hover:!text-white border-[#4F008A1A] border-[1px] px-4 rounded mb-3 mb-lg-0 pt-[30px] text-[#656468] pb-4 hover:bg-[#3DA84A]">
             <div className="d-flex justify-content-between align-items-center mb-3">
               {/* <ImageComponent src="/icon/wallet.png" width={60} height={60} /> */}
               <div className=' text-[18px]'>{item?.name}</div>
               <div>
              
               </div>
             </div>
          
             <div className=" text-[#060D1C] group-hover:text-white font-5 fw-semibold text-[25px]">
                 {item?.count}
             </div>
           </div>
         </div>
       </div>
        )}
       
     
       
      </div>
      {/*  */}
     
      {/*  */}
     

    
      <div>{/* <MapWithMarker locations={location}/> */}</div>
    </div>
  );
}
