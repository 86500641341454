import React, { useEffect, useState } from "react";
import { ImageComponent } from "../../Common/FallbackImage";
import ButtonComp from "../../Ui/Button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import InputComp from "../../Ui/InputComp";
import {
  useGetAllCompaniesQuery,
  useGetAllCompanyManagersQuery,
} from "../../../store/DashBoard/DashBoardApi";
import ManagerPage from "./submodules/ManagerPage";
import Header from "./submodules/Header";
import ShowProfileTop from "./submodules/showProfileTop";
import TopHeader from "../../Common/TopHeader";
import CompanyManagersComp from "./submodules/CompanyManagers";
import { BsCalendar2Event } from "react-icons/bs";
import SideDesc from "./submodules/SideDesc";
import { useForm } from "react-hook-form";

export default function ViewCompanyComp({
  listPage,
  setListPage,
  handleOnSubmit,
  showImage,
  fileLoading,
  handleFileChange,
  handleButtonClick,
  fileInputRef,
  control,
  handleSubmit,
  setValue,
  errors
  
}) {
  const [company, setCompany] = useState();
  const location = useLocation();
  const { id } = useParams();

  // const {
  //   control,
  //   handleSubmit,
  //   setValue,
  //   setError,
  //   watch,
  //   getValues,
  //   clearErrors,
  //   reset,
  //   formState: { errors, isValid },
  // } = useForm({
  //   defaultValues: {
  //     firstName: "",
  //     lastName: "",
  //     email: "",
  //     address: "",
  //     contactPhoneNo: "",
  //     contactPerson: "",
  //     state: "",
  //     country: "",
  //     image: "",
  //   },
  // });

  useEffect(() => {
    if (id) {
      setValue("id", location?.state?._id),
        setValue("firstName", location?.state?.firstName);
      setValue("lastName", location?.state?.lastName);
      setValue("email", location?.state?.email);
      setValue("contactPhoneNo", location?.state?.phoneNumber);
      setValue("country", location?.state?.country);
      setValue("state", location?.state.state);

      //phoneNumber
      //state
    }
  }, [location?.state?._id]);

  const navigate = useNavigate();

  console.log(location?.state, "location");

  const {
    data: Companies,
    // isLoading: CustomerLoader,
    // isSuccess: CustomerSuccess,
  } = useGetAllCompaniesQuery();
  const {
    data: CompanyManagers,
    // isLoading: CustomerLoader,
    // isSuccess: CustomerSuccess,
  } = useGetAllCompanyManagersQuery(id, {
    skip: !id,
    refetchOnMountOrArgChange: true,
  });
  //useGetAllCompanyManagersQuery

  useEffect(() => {
    if (id) {
      setCompany(Companies?.data?.companies?.find((item) => item?._id === id));
    }
  }, [id, Companies?.data?.companies]);

  const formList = [
    {
      name: "firstName",
      placeholder: "",
      label: "First Name",
      // value: company?.name,
    },
    {
      name: "lastName",
      placeholder: "",
      label: "Last Name",
      // value: company?.contactPerson,
    },
    {
      name: "country",
      placeholder: "Nigeria",
      style: "col-lg-6",
      value: company?.country,
    },
    {
      name: "state",
      placeholder: "Lagos",
      style: "col-lg-6",
      // value: company?.state,
    },
    {
      name: "email",
      label: "Email Address",
      placeholder: "",
      // value: company?.email,
    },
    {
      name: "contactPhoneNo",
      placeholder: "8000000000",
      iconRight: "+234",
      // value: company?.contactPhoneNo,
    },
    {
      name: "Company Full Address",
      placeholder: "216, Broad street, Lagos",
      // value: company?.address,
    },
  ];

  return (
    <div className={`flex gap-2 h-min-[90vh]`}>
      <div className={`pb-[69px] w-full`}>
        <ShowProfileTop
          company={company}
          handleOnSubmit={handleSubmit(handleOnSubmit)}
          handleButtonClick={handleButtonClick}
          uploadImage={showImage}
          fileLoading={fileLoading}
          fileInputRef={fileInputRef}
          handleFileChange={handleFileChange}
        />

        {/*  */}
        <ManagerPage errors={errors} control={control} formList={formList} />
      </div>
    </div>
  );
}
